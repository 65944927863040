// Gallery.jsx
import React from 'react';
import SimpleReactLightbox, {SRLWrapper} from "simple-react-lightbox";
import {StaticImage} from "gatsby-plugin-image";
import Layout from "../components/layout";

const GalleryUK1990 = () => {
    return (
        <Layout>
            <a href="/Gallery">Return to Gallery</a>
            <h1>UK 1970 - 1990</h1>
            <SimpleReactLightbox><SRLWrapper>
                <StaticImage src="../images/gallery/UK1990/407489_orig.jpg"
                             alt="Greystones Karate Club - early 90's"/><StaticImage
                src="../images/gallery/UK1990/8062626_orig.jpg"
                alt="Greystones students at Eric Mathers Dromfield Dojo during Sensei Ravey's class. _, _, Simon Golland, Sensei Ravey, Ruth Turner, _, Sensei Mick Warrington"/><StaticImage
                src="../images/gallery/UK1990/8438454_orig.jpg" alt=""/><StaticImage
                src="../images/gallery/UK1990/8591088_orig.jpg" alt="R: Simon Golland, Greystones Karate Club"/>
            </SRLWrapper></SimpleReactLightbox>
        </Layout>
    );
}

export default GalleryUK1990;
